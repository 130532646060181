import { useAuthStore } from "~~/store/auth"
export default defineNuxtRouteMiddleware((to) => {
    const auth = useAuthStore()
		useNuxtApp().$api.get('/checkSession').then((res) => {
			if(!res.data.isLoggedIn) {
				auth.user = auth.token = null
				auth.isLoggedIn = false
				localStorage.removeItem('user')
				localStorage.removeItem('token')
				localStorage.removeItem('isLoggedIn')
			}
		})
    if(auth.isLoggedIn && (to?.name == 'page-login___en' || to?.name == 'page-register___en' || to?.name == 'page-verify_email___en')) {
        return navigateTo('/')
    }
    if(!auth.isLoggedIn && (to?.name != 'page-login___en' && to?.name != 'page-register___en' && to?.name != 'page-verify_email___en' )) {
        abortNavigation()
        return navigateTo('/page/login')
    }
})